import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../components/Core';
import { Link } from 'gatsby';
import API from '../../api/api';
import { RenderPricingBoxes } from './RenderPricingBoxes';
import { OdinProductSchema } from '../../utils/OdinProductSchema';
import GlobalContext from './../../context/GlobalContext';
import { findOfferParams, findCodeParams } from '../../utils/helperFn';

const ButtonGroup = styled.div`
  border-radius: 50px;
  padding: 8px 2px;
  background-color: #fff;
  display: inline-flex;
  border: 2px solid #07131e;

  .btn {
    border-radius: 50px;
    border: 2px solid #07131e;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #19191b;
    margin: 0 6px;

    &.active {
      background: #08de9e;
      color: #19191b;
      box-shadow: none;
      border: 2px solid #07131e;
    }
  }
`;

const DynamicPricingSection = (props) => {
  const [fetchedOffer, setFetchedOffer] = useState(null);
  const [productCategoryFilter, setProductCategoryFilter] = useState('BROADBAND');
  const [codeParamsVar, setCodeParamsVar] = useState(false);
  const [offerAPIError, setOfferAPIError] = useState(false);
  const gContext = useContext(GlobalContext);
  const offerParams = findOfferParams(props.searchParams);

  var urlLocation = props.location === 'business' ? 'business' :
    gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.Classification?.toLowerCase() ?
      gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.Classification?.toLowerCase() :
      "residential"
    ;

  useEffect(() => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      CustomerType: urlLocation.toUpperCase(),
    });

    var codeParams = props.codeParams ? findCodeParams(props.codeParams) : false;
    setCodeParamsVar(codeParams);

    const fetchData = async () => {
      gContext.setQuoteLoading(true);
      try {
        setFetchedOffer(undefined);
        const query = `/ProductModule/v1.0/cst/Offer/active/${urlLocation.toUpperCase()}?filters=ContractType:${
          gContext.productContractFilter === 'contractLow' ? 'MONTHLY' : 'CONTRACT'
        },IsDefault:true${
          offerParams
            ? '&code=' + offerParams.code + ''
            : codeParams
            ? '&code=' + codeParams + ''
            : ''
        }`;
        const response = await API.get(query);
        setFetchedOffer(response.data.data);
        gContext.setQuoteLoading(false);
        const offerAddonsArray = [];
        const offerAddons = response.data.data.Product.dbRecords.filter(
          (product) => product.properties.Type === 'ADD_ON_PRODUCT',
        );
        offerAddons.forEach((item) => {
          offerAddonsArray.push(item.properties.DisplayName);
        });

        /* Pass selected contractType along the order flow. */
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          contractType: gContext.productContractFilter === 'contractLow' ? 'MONTHLY' : 'CONTRACT',
          offerAddons: offerAddonsArray,
        });

        const preselectedProduct = response.data.data.Product.dbRecords.find((product) => {
          return (
            product.properties.DisplayName &&
            product.properties.DisplayName.indexOf('You 1000') > -1 &&
            product.properties.AvailableAddons &&
            product.properties.AvailableAddons.filter(
              (addon) => addon.properties.DisplayName === 'YouMesh',
            ).length &&
            product.properties.ContractType === 'ANNUAL_18'
          );
        });

        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          offer: response.data.data,
        });

        if (preselectedProduct && !gContext.goGetRegistrationData()?.selectedProduct) {
          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            selectedProduct: preselectedProduct,
            orderItems: [preselectedProduct],
          });
        }
      } catch (error) {
        console.log('%c Error fetching offer.', 'color:red', error);
        setOfferAPIError(true);
        gContext.setQuoteLoading(false);
      }
    };
    fetchData();
  }, [gContext.productContractFilter, props.location]);

  useEffect(() => {
    gContext.setProductContractFilter(
      gContext.goGetRegistrationData()?.selectedProduct?.properties?.ContractType === 'MONTHLY'
        ? 'contractLow'
        : 'contractHigh',
    );
  }, [gContext.goGetRegistrationData()?.selectedProduct]);

  const renderAPIErrorInformation = () => (
    <Alert variant="danger" className="text-center">
      <Alert.Heading className="mt-4">We are experiencing issues with our servers</Alert.Heading>
      <p className="mt-4">
        We are currently unable to show you our latest offer. We kindly ask you to try again in few
        minutes.
      </p>
    </Alert>
  );

  const renderPricingSwitches = () => (
    <Row id="pricing-section" className={`${props.location === 'business' ? 'pt-0' : ''}`}>
      <Col sm="12" className="text-center mb-4">
        <ButtonGroup>
          {urlLocation !== 'business' &&
            !gContext.goGetRegistrationData()?.personalData?.businessSwitch &&
            !gContext.goGetRegistrationData()?.personalData?.companyName &&
            !codeParamsVar && (
              <span
                role="button"
                className={`btn btn-filter ${
                  gContext.productContractFilter === 'contractLow' ? 'active' : ''
                }`}
                onClick={() => {
                  gContext.setProductContractFilter('contractLow');
                }}
                onKeyDown={() => {
                  gContext.setProductContractFilter('contractLow');
                }}
                tabIndex={0}
              >
                {OdinProductSchema[urlLocation][productCategoryFilter].contractLow.title}
              </span>
            )}
          <span
            role="button"
            className={`btn btn-filter ${
              gContext.productContractFilter === 'contractHigh' ? 'active' : ''
            }`}
            onClick={() => {
              gContext.setProductContractFilter('contractHigh');
            }}
            onKeyDown={() => {
              gContext.setProductContractFilter('contractHigh');
            }}
            tabIndex={0}
          >
            {OdinProductSchema[urlLocation][productCategoryFilter].contractHigh.title}
          </span>
        </ButtonGroup>
      </Col>
    </Row>
  );

  const renderPricingBody = () => (
    <>
      <Row className="justify-content-center reverse-order-mobile pricing-body">
        {
          <>
            <RenderPricingBoxes
              rawOffer={fetchedOffer}
              productCategory={productCategoryFilter}
              contractType={
                OdinProductSchema[urlLocation][productCategoryFilter][
                  gContext.productContractFilter
                ].value
              }
              location={urlLocation}
              slug={props.location}
              checkoutLanding={props.checkoutLanding}
              lead={props.lead}
              sprn={props.sprn}
              fullName={props.fullName}
            />
            <div className="text-center">
              <small>
                Prices available until 28/11/2024. See our{' '}
                <Link
                  className="underlined"
                  to="/legal/offer-terms-and-conditions-september2024"
                >
                  offer terms
                </Link>{' '}
                for full details.{' '}
                <Link className="underlined" to="/legal/website-terms-and-conditions">
                  Terms and conditions
                </Link>{' '}
                apply.
              </small>
            </div>
          </>
        }
      </Row>
    </>
  );

  return (
    <Section className={`pricing-section light-bg pricing-section-${props.location}`}>
      <Container>
        {offerAPIError ? renderAPIErrorInformation() : renderPricingSwitches()}
        {offerAPIError ? <div /> : renderPricingBody()}
      </Container>
    </Section>
  );
};

export default DynamicPricingSection;
